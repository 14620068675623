<template>
  <div class="products">
    <top-banner 
      title="Projects"
      subtitle="We stick to good discipline of software engineering to accomplish better project success."
      :topimage="image"
    />

    <navbar/>

    <ProjectsList />
  </div>
</template>

<script>
import image from "./../assets/img/product.png"
import TopBanner from '../components/TopBanner.vue'
import Navbar from '../components/Navbar.vue'
import ProjectsList from '../components/ProjectsList.vue'

export default {
  name: 'Projects',
  components: {
    TopBanner,
    Navbar, 
    ProjectsList,
    
  },
  data() {
    return{
      image,
    }
  }
}
</script>