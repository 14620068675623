<template>
  <v-app class="main-app">

    <router-view />

    <!-- <v-main>
      <v-scale-transition>
        <v-btn
          fab
          v-show="fab"
          v-scroll="onScroll"
          dark
          fixed
          bottom
          right
          color="secondary"
          @click="toTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-scale-transition>
    </v-main> -->

    <foote />
    
  </v-app>
</template>

<script>

import foote from '@/components/Footer'


export default {
    name: 'App',
    components: {
      
        foote,
        
    },

    data: () => ({
        //
    }),
};
</script>

<style>
* {
  box-sizing: border-box;
}
.center{
  width:90%;
  margin:auto;
}
.main-app{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
</style>
