<template>
    <div class="center">
        <v-row class="system-main-container">
            <v-col cols="12" md="8" style="padding-top:50px; padding-left:30px;">
                <div class="product-container">
                    <div><br/>
                        <h1 class="medium-title">Our Sustainable Products.</h1>
                        <p style="margin:10px 0 20px 0;">Developed and Managed with Professional Services and Technologies.</p>
                        
                        <v-expansion-panels flat class="product-text-div" v-model="panel">
                            <v-expansion-panel
                                v-for="(item,i) in items"
                                :key="i"
                            >
                            <v-expansion-panel-header expand-icon="" style="font-size:20px; font-weight:600;">
                               <span style="color:#77C74E;" v-if="panel===i">
                                   <v-icon color="#77C74E">mdi-play</v-icon> 
                                    {{ item.name }}
                                </span> 
                                <span v-else>
                                    {{ item.name }}
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                {{ item.description }}
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </div>

                    <div><br/>
                        <v-card class="image-card" flat>
                            <v-img class="website-image" :src="items[panel].image"></v-img>
                        </v-card>
                    </div>

                </div>
            </v-col>
            <v-col cols="12" md="4" class="green-container">
                <div class="green-div"></div>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import Data from './../data';

export default {
    data() {
        return {
            panel: 0,
            items: Data.products.slice(0,4)
        }
    }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-enter{
  transform: translateX(40px);
  opacity: 0;
}

.system-main-container{
    position: relative;
}
.green-div{
    background:#EEFFE4;
    height:400px;
}
.product-container{
    display: flex;
}
.image-card{
    width: 650px;
    height:370px;
    position:absolute;
    right:100px;
    top:170px;
    background: transparent !important;
}
.website-image{
    width:100%;
    height: 100%;
}
.product-text-div{
    width:60%;
}

/* --------- Responsive ---------- */
@media only screen and (max-width: 400px) {
    .image-card{
        position: static;
    }
    .product-container{
        display: block;
    }
    .product-text-div{
        width:100%;
    }   
    .green-container{
        display: none;
    }
}
@media only screen and (min-width: 400px) {
    .image-card{
        position: static;
    }
    .product-container{
        display: block;
    }
    .product-text-div{
        width:100%;
    } 
    .green-container{
        display: none;
    }
   
}
@media only screen and (min-width: 600px) {
    .image-card{
        position: static;
    }
    .product-container{
        display: block;
    }
    .product-text-div{
        width:100%;
    } 
    .green-container{
        display: none;
    }
}
@media only screen and (min-width: 768px) {
    .image-card{
        position: absolute;
        width: 300px;
        height:210px;
        right:20px;
        top:200px;
    }
    .product-container{
        display: flex;
    }
    .product-text-div{
        width:50%;
    }
    .green-container{
        display: none;
    }
}
@media only screen and (min-width: 880px) {
    .image-card{
        position: absolute;
        width: 350px;
        height:230px;
        right:20px;
        top:200px;
    }
    .product-container{
        display: flex;
    }
    .product-text-div{
        width:50%;
    }
    .green-container{
        display: none;
    }
}
@media only screen and (min-width: 992px) {
    .image-card{
        position: absolute;
        width: 450px;
        height:280px;
        right:40px;
        top:200px;
    }
    .product-container{
        display: flex;
    }
    .product-text-div{
        width:55%;
    } 
    .green-container{
        display: block;
    }
}
@media only screen and (min-width: 1200px) {
    .image-card{
        position: absolute;
        width: 500px;
        height:300px;
        right:60px;
        top:170px;
    }
    .product-container{
        display: flex;
    }
    .product-text-div{
        width:55%;
    } 
    .green-container{
        display: block;
    }
}
@media only screen and (min-width: 1300px) {
    .image-card{
        position: absolute;
        width: 550px;
        height:350px;
        right:80px;
        top:170px;
    }
    .product-container{
        display: flex;
    }
    .product-text-div{
        width:60%;
    } 
    .green-container{
        display: block;
    } 
}
@media only screen and (min-width: 1400px) {
    .image-card{
        position: absolute;
        width: 650px;
        height:370px;
        right:100px;
        top:170px;
    }
    .product-container{
        display: flex;
    }
    .product-text-div{
        width:60%;
    } 
    .green-container{
        display: block;
    }
}
</style>
