<template>
  <div class="products">
    <top-banner 
      title="Our Products" 
      subtitle="At Sustainable Technological Solutions, we guarantee on providing rapid, reliable and robust information technology solutions that work." 
      :topimage="image"
    />

    <navbar/>

    <ProductsList />
  </div>
</template>

<script>
import image from "./../assets/img/product.png"
import TopBanner from '../components/TopBanner.vue'
import Navbar from '../components/Navbar.vue'
import ProductsList from '../components/ProductsList.vue'

export default {
  name: 'Products',
  components: {
    TopBanner,
    Navbar,
    ProductsList,
    
  },
  data() {
    return{
      image,
    }
  }
}
</script>