<template>
    <div>
        <div style="padding:20px 0;" class="center">
            <h4 class="approach-above-main-title">START WITH A DEMO</h4>
            <h1 class="approach-main-title medium-title"><span style="color:#47D14F;">Here are Some Products</span> We Have Been A Part of.</h1>
        </div>

        <div v-for="product in products" :key="product.id">
            <div v-if="(product.id)%2 != 0" style="background:#F3F3F3;">
                <v-row class="center">
                    <v-col cols="12" sm="6" class="column1">
                        <h2 class="product-title">{{ product.name }}</h2>
                        <p style="line-height:1.8;">{{ product.description }}</p>
                        <v-btn
                            color="#77C74E"
                            dark
                            @click="showForm"
                        >
                            Request a Demo
                        </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" class="column1">
                        <v-img :src="product.image" class="product-img" contain/>
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <v-row class="center">
                    <v-col cols="12" sm="6" class="column1">
                        <v-img :src="product.image" class="product-img" contain/>
                    </v-col>
                    <v-col cols="12" sm="6" class="column1">
                        <h2 class="product-title">{{ product.name }}</h2>
                        <p style="line-height:1.8;">{{ product.description }}</p>
                        <v-btn
                            color="#77C74E"
                            dark
                            @click="showForm"
                        >
                            Request a Demo
                        </v-btn>
                        
                    </v-col>      
                </v-row>

            </div>

        </div>

    
        <div class="center">
            <v-pagination
                v-if="totalItems>pageLimit"
                v-model="page"
                @input="changePage"
                :length="Math.ceil(totalItems/pageLimit)"
                color="#77C74E"
                style="margin-top:20px;"
            ></v-pagination>
        </div><br/>

        <request-demo-form v-model="value"/>
    </div> 
</template>


<script>
import Data from './../data'
import RequestDemoForm from './RequestDemoForm.vue'
export default {
  components: { RequestDemoForm },
    name:'ProductsList',
    data(){
        return{
            value:false,
            page:1,
            products: Data.products,
            pageLimit: 4,
            totalItems: Data.products.length
        }
    },
    created() {
        this.products = Data.products.slice((this.page-1)* this.pageLimit, this.page* this.pageLimit) 
    },
    methods: {  
        changePage(page){
            this.products = Data.products.slice((page-1)* this.pageLimit, page* this.pageLimit) 
        },
        showForm() {
            this.value = true;
        }
    },
}
</script>

<style scoped>
.approach-above-main-title{
    text-align:center;
    color:#ABABAC;
    letter-spacing: 3px;
    margin:10px 0;
}
.approach-main-title{
    text-align:center;
    font-weight: 600;
}
.product-title{
    color:#77C74E;
    margin-bottom: 10px;
    font-size:30px;
}
.column1{
    padding:40px;
    margin:auto;
}
.product-img{
    width:100%;
    height: 300px;
}
</style>