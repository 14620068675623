<template>

    <div class="approach-container">
        <div class="center"><br/><br/>
        <v-row class="center">
            <v-col cols="12" sm="6" md="5" lg="4">
                <div style="margin:30px 0;">
                    <h1 class="approach-main-title medium-title">Solutions And Focus Areas</h1>
                    <p style="margin:10px 0;">Our Approach On Digital Solutions</p>
                </div><br/>

                <v-card class="approach-card text-center">
                    <v-card-text class="approach-card-text">
                        <v-img :src="solutions[solutions.length-1].img3" class="approach-img"/>
                        <br/>
                        <h2 class="font-weight-bold">{{ solutions[solutions.length-1].title }}</h2>
                        <br/>

                        <p class="approach-description">
                            {{ solutions[solutions.length-1].text }}
                        </p>
                    </v-card-text>
                </v-card>

            </v-col>

            <v-col cols="12" sm="6" md="7" lg="8">
                <v-row align="center" justify="space-around">
                    <v-col cols="12" sm="12" md="6" class="text-center" v-for="(solution, i) in solutions.slice(0, -1)" :key="i">
                        <v-card class="approach-card">
                            <v-card-text class="approach-card-text">
                                <v-img :src="solution.img3" class="approach-img"/>
                                <br/>
                                <h2 class="font-weight-bold">{{ solution.title }}</h2>
                                <br/>

                                <p class="approach-description">
                                    {{ solution.text }}
                                </p>
                            </v-card-text>
                        </v-card>

                    </v-col> 
                </v-row>
            </v-col>
        </v-row>
        </div><br/><br/>
    </div>
</template>

<script>
import Data from './../data'

export default{
    data(){
        return{
            solutions: Data.it_solutions
        }
    }
}
</script>

<style scoped>
.approach-container{
    background:#F3F3F3;
}
.approach-above-main-title{
    color:#232323;
    margin:5px 0;
    text-decoration: underline;
    -webkit-text-decoration-color: #77C74E;
    text-decoration-color: #77C74E;
    text-decoration-thickness: 3px;
}
.approach-main-title{
    font-weight: 600;
    font-size:40px;
}
.approach-card{
    background: #ffffff;
    position: relative;
    transition: .2s ease;
    border:0.7px solid #dedede;
    box-shadow: none !important;
}
.approach-description{
    line-height:1.8;
}
.approach-card-text{
    padding:30px;
    color:#2D2C2C !important;
}
.approach-img{
    width:100px;
    margin:auto;
}
.approach-card:hover{
    -ms-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.1) !important;
}

</style>