<template>
  <div class="home">
    <herocomponents />
    <navbar/>
    
    <!-- <Read />
    <br/><br/> -->

    <approach/>

    <Systems />
    <br/><br/><br/><br/>

    <Clients />
    <br/>

    <Update />
    <br />
    
    <Contact />
    <br/><br/>
    
  </div>
</template>

<script>
import Systems from '@/components/Systems.vue'
import Approach from '../components/Approach.vue'
import Clients from '../components/Clients.vue'
import Update from '../components/Update.vue'
import Contact from '../components/Contact.vue'
import Herocomponents from '../components/Herocomponents.vue'
import Navbar from '../components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    
    Systems,
    Approach,
    Clients,
    Update,
    Contact,
    Herocomponents,
    Navbar,
 
  }
}
</script>