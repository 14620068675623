import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ITSolutions from '../views/ITSolutions.vue'
import Products from '../views/Products.vue'
import Projects from '../views/Projects.vue'
import Contactus from '../views/Contactus.vue'
import '../views/style.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import("../views/About.vue"),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import("../views/Careers.vue"),
  },
  {
    path: '/careers/:id',
    name: 'Careers.page',
    component: () => import("../views/CareerPage.vue"),
  },
  {
    path: '/itsolutions',
    name: 'ITSolutions',
    component: ITSolutions
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/news',
    name: 'News',
    component: () => import("../views/News.vue"),
  },
  {
    path: '/news/:id',
    name: 'Newspage',
    component: () => import("../views/Newspage.vue"),
  },
  {
    path: '/contactus',
    name: 'Contactus',
    component: Contactus
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import("../views/Terms.vue"),
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import("../views/Support.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
