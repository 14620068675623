<template>
  <div class="it">
    <backgroundcontact />

    <navbar/>
    <br/><br/>

  </div>
</template>
<script>
import backgroundcontact from '@/components/backgroundcontact.vue'
import Navbar from '../components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    backgroundcontact,
    Navbar
 
  }
}
</script>