<template>
<div>

  <div class="center" style="text-align:center;">
    <h4 class="client-above-main-title">START WITH A DEMO</h4>
    <h1 class="client-main-title medium-title"><span style="color:#47D14F;">Explore Various Projects</span> that We Have Completed</h1>

    <v-tabs
      v-model="tab"
      centered
      hide-slider
    >
        <v-tab
            v-for="(project,i) in project_types"
            :key="i"
            @click="changeContent(project)"
        >
            {{ project }}
        </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item 
          v-for="(project,i) in project_types"
          :key="i"
          transition="fade-transition"
      >
        <v-card flat>
          <v-card-text>
            <v-row v-if="project == 'All'" align="center" justify="space-around">
              <v-col cols="12" sm="6" md="4" lg="3" 
                  v-for="(content,i) in contents.slice(0,4)"
                  :key="i"
                  style="padding:30px 25px 25px 25px;"
              >
                <a :href="content.link" target="_blank" style="text-decoration:none;">
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div class="flip-box-front">
                        <v-img :src="content.image" class="project-image"/>
                      </div>
                      <div class="flip-box-back">
                        <h2 style="color:#77C74E;">{{ content.name }}</h2>
                        <p style="margin-top:10px" v-html="content.description"/>>
                      </div>
                    </div>
                  </div>
                  <div style="text-align:left;" class="project-name">{{ content.name }}</div>
                </a>
              </v-col> 
            </v-row>

             <v-row v-if="project == 'Design Development'" align="center" justify="space-around">
              <v-col cols="12" sm="6" md="4" lg="3" 
                  v-for="(content,i) in projects.slice(0,4)"
                  :key="i"
                  style="padding:30px 25px 25px 25px;"
              >
                <a :href="content.link" target="_blank" style="text-decoration:none;">
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div class="flip-box-front">
                        <v-img :src="content.image" class="project-image"/>
                      </div>
                      <div class="flip-box-back">
                        <v-row style="margin:auto">
                          <v-col cols="12" sm="6" v-for="image in content.images" :key="image">
                            <v-img :src="image" style="width:100%; height:auto;"/>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                  <div style="text-align:left;" class="project-name">{{ content.name }}</div>
                </a>

              </v-col> 
            </v-row>

            <v-row v-else align="center" justify="space-around">
              <v-col cols="12" sm="6" md="4" lg="3" 
                  v-for="(content,i) in projects.slice(0,4)"
                  :key="i"
                  style="padding:30px 25px 25px 25px;"
              >
                <a :href="content.link" target="_blank" style="text-decoration:none;">
                  <div class="flip-box">
                    <div class="flip-box-inner">
                      <div class="flip-box-front">
                        <v-img :src="content.image" class="project-image"/>
                      </div>
                      <div class="flip-box-back">
                        <h2 style="color:#77C74E;">{{ content.name }}</h2>
                        <p style="margin-top:10px">{{ content.description }}</p>
                      </div>
                    </div>
                  </div>
                  <div style="text-align:left;" class="project-name">{{ content.name }}</div>
                </a>
              </v-col> 
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-btn color="#77C74E" to="/projects" large dark depressed style="margin:20px 0; border-radius:0;">All Projects</v-btn>

  </div><br />

    
  <div class="center">
    <v-divider style="margin:0 20px"></v-divider>
    <br/><br />

    <v-carousel class="carousel" :show-arrows="false" cycle light hide-delimiter-background>
      <v-carousel-item v-for="(newscontent,i) in news" :key="i" reverse-transition="fade-transition" transition="fade-transition">
        <v-row>
          <v-col cols="12" sm="4" class="text-column">
            <h2 class="medium-title" style="margin-bottom:20px;">Latest news &
              insights</h2>
            <router-link :to="'/news/' + newscontent.id" style="text-decoration:none;" class="news-link">
              <h2 class="news-title">{{ newscontent.title }}</h2>
            </router-link>
            <p class="description" v-html="newscontent.description"></p>
          </v-col>
          <v-col cols="12" sm="8">
            <img :src="newscontent.src" style="width:100%;height:400px;"/> 
          </v-col>
          
        </v-row>
      </v-carousel-item>

    </v-carousel><br />
  </div><br />
</div>
</template>

<script>
import Data from './../data'

export default{
  data(){
    return{
        tab: null,
        project_types: [
          'All', 'Web Development', 'Android Development', 'Design Development'
        ],  
        contents: Data.projects,
        projects:[],
        news: Data.news
    }
  },
  methods: {
    changeContent(project) {
      this.projects = this.contents.filter(item => item.type === project)
    }
  }
}
</script>

<style scoped>
.client-container{
  background:#F3F3F3;
}
.client-main-title{
  color:#2D2C2C;
  margin-bottom:20px;
}
.client-above-main-title{
  color:#ABABAC;
  letter-spacing: 3px;
  margin:10px 0;
}
.project-image{
  height:310px;
  box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.1);
}
.project-name{
  font-size:18px;
  margin-top:15px;
  color: #000000;
}
.text-column{
  padding:0 30px 20px 20px;
}
.description{
  font-size:14px;
  color:#2D2C2C;
  text-align: justify;
  text-justify: inter-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height:85px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin-top:20px;
}
.carousel{
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff;
  min-height:400px;
}
.flip-box {
  background-color: transparent;
  height:310px;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: #54595F;
  color: white;
  transform: rotateY(180deg);
  padding:20px;
}
.news-link{
  color:#77C74E;
}
.news-link:hover .news-title{
  text-decoration: underline;
}
</style>


