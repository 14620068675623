<template>

    <div class="approach-container">
        <div class="center" align="center" justify="center"><br/>
            <div style="margin-bottom:20px;">
                <h4 class="approach-above-main-title">MANAGED IT SOLUTION</h4>
                <h1 class="approach-main-title medium-title">Our Approach</h1>
                <h1 class="approach-main-title medium-title">On <span style="color:#47D14F;">Digital Solutions</span></h1>
            </div>

            <v-row align="center" justify="space-around">
            
                <v-col cols="12" sm="6" md="4" lg="4" class="text-center" v-for="(feature, i) in features" :key="i">

                    <v-card class="approach-card" flat>

                        <div class="overlay">  
                        </div>

                        <v-card-text class="approach-card-text">
                            <v-img :src="feature.img3" class="approach-img"/>
                            <br/>
                            <h2 class="font-weight-bold">{{ feature.title }}</h2>
                            <br/>

                            <p class="approach-description">
                                {{ feature.text }}
                            </p>
                        </v-card-text>
                        
                    </v-card>

                </v-col>
            </v-row><br/>

            <v-btn color="#77C74E" to="/itsolutions" depressed large dark style="margin:10px 0; border-radius:0;">View more</v-btn>

        </div><br/><br/>
    </div>
</template>

<script>
import Data from './../data'

export default{
    data(){
        return{
            features: Data.it_solutions.slice(0,3)
        }
    }
}
</script>

<style scoped>
.approach-container{
    background: #F3F3F3;
}
.approach-above-main-title{
    text-align:center;
    letter-spacing: 3px;
    color:#ABABAC;
    margin:10px 0;
}
.approach-main-title{
    text-align:center;
    font-weight: 600;
}
.approach-card{
    background: #ffffff;
    position: relative;
}
.approach-description{
    line-height:1.8;
}
.overlay {
  position: absolute;
  border-radius:4px;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #77C74E;
  overflow: hidden;
  width: 100%;
  height:0;
  transition: .5s ease;
}

.approach-card:hover {
  box-shadow: 1px 3px 9px 3px rgba(0, 0, 0, 0.1) !important;
}

.approach-card:hover .overlay {
  bottom: 0;
  height: 100%;
}
.approach-card:hover .approach-card-text {
  color: #ffffff !important;
  position: relative;
}
.approach-card-text{
    padding:40px;
    color:#2D2C2C !important;
}
.approach-img{
    width:100px;
    margin:auto;
}
</style>