<template>
  <v-dialog v-model="value" persistent max-width="300">
    <v-card class="form-card">    
        <v-card-text>
            <v-row class="pb-4">
                <v-col cols="9" style="margin:auto 0;">
                    <h2 style="color:#2D2C2C;">Request a Demo</h2>
                </v-col>
                <v-col cols="3" style="text-align:right;">
                    <v-btn
                        icon
                        @click="changeValue()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            Your Name
            <v-text-field
                placeholder="Alish Dahal"
                dense
                required
            ></v-text-field>
            
            Your Email
            <v-text-field
                placeholder="example@gmail.com"
                dense
                required
            ></v-text-field>
            
            Your Contact Number
            <v-text-field
                placeholder="9847627889"
                dense
                required
            ></v-text-field>

            <v-btn
                color="#77C74E"
                dark
                style="margin-top:10px;"
                @click="dialog = false"
            >
                Request
            </v-btn>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },

  },
  methods: {
    changeValue() {
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.form-card{
    padding:15px 0;
    border: 1px solid #77C74E;
    border-radius:20px;
    background: linear-gradient(#E5FFD7,#FFECE7);
}
</style>