<template>
  <div class="it">
    <backgroundit
    />
  
    <navbar />

    <approach-content />
   
  </div>
</template>

<script>
import ApproachContent from '../components/ApproachContent.vue'
import Navbar from '../components/Navbar.vue'
import Backgroundit from '../components/backgroundit.vue'

export default {
  name: 'ITSolutions',
  components: {
    ApproachContent,
    Navbar,
    Backgroundit,

  },
}
</script>