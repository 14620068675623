<template>
    <div>
        <section id="download">
            <div :style="{ backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url(${peopleimage})`}" class="imge" :src="peopleimage">

                <h1 class="contact-main-title medium-title" justify="center" align="center">
                    We'll Help You Overcome Your Technology Challenges
                </h1>
            
                <v-card class="main-card center">

                    <v-card-text style="color:#000000;">
                        <v-row>
                            <v-col cols="12" sm="4" md="5" style="margin:auto;">
                                <v-img src="./../assets/img/Image 30.png" class="contact-img"/>
                            </v-col>

                            <v-col cols="12" sm="8" md="7" class="contact-card-text">
                                <v-card-title class="contact-card-title">
                                    Get started. Work with us.
                                </v-card-title>
                                <v-card-text style="color:#000000;">
                                    <p style="margin:10px 0 20px 0;">
                                        From flexible working to opportunities for movement and growth, we are committed to making your days easier, too. Work with us and we’ll make it work for you.          
                                    </p>
                                    <v-btn color="#77C74E" to="/contactus" large dark depressed>Contact Us</v-btn>
                                </v-card-text>
                            </v-col>
                            
                        </v-row>

                    </v-card-text>

                </v-card>

            </div>
        </section>
    </div>
</template>



<script>
import peopleimage from "@/assets/img/people.jpg"
export default {
    data() {
        return {
            peopleimage,

            screenHeight: window.screen.availHeight - (0.15 * window.screen.availHeight),
        }
    }
}
</script>

<style scoped>
.contact-main-title{
    width:50%;
    margin:auto;
    padding-top:80px;
}
.contact-card-title{
    font-weight:700;
}
.imge{
    position: relative;
    margin-bottom:150px;
    color:#ffffff;
    height: 400px;
}
.main-card{
    position:absolute;
    bottom:-150px;
    left:0;
    right:0;
    box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.1) !important;
}
.contact-img{
    width:70%;
    height:auto;
    margin:auto;
}
.contact-card-text{
    margin:auto; 
    padding:1rem 2rem;
}

/* --------- Responsive ---------- */

@media only screen and (max-width: 400px) {
    .contact-card-title{
        font-size:22px;
    }
    .contact-card-text{
        padding:1rem 1.2rem;
    }
    .contact-main-title{
        padding-top:20px;
        width:70%;
    }
    .contact-img{
        width:100%;
    }
    .imge{
        height: 700px;
    }
}
@media only screen and (min-width: 400px) {
    .contact-card-title{
        font-size:22px;
    }
    .contact-card-text{
        padding:1rem 1.3rem;
    }
    .contact-main-title{
        padding-top:30px;
        width:65%;
    }
    .contact-img{
        width:95%;
    }
    .imge{
        height: 700px;
    }
}
@media only screen and (min-width: 600px) {
    .contact-card-title{
        font-size:24px;
    }
    .contact-card-text{
        padding:1rem 1.4rem;
    }
    .contact-main-title{
        padding-top:40px;
        width:65%;
    }
    .contact-img{
        width:90%;
    }
    .imge{
        height: 400px;
    }
}
@media only screen and (min-width: 768px) {
    .contact-card-title{
        font-size:26px;
    }
    .contact-card-text{
        padding:1rem 1.5rem;
    }
    .contact-main-title{
        padding-top:50px;
        width:60%;
    }
    .contact-img{
        width:85%;
    }
    .imge{
        height: 400px;
    }
}
@media only screen and (min-width: 880px) {
    .contact-card-title{
        font-size:28px;
    }
    .contact-card-text{
        padding:1rem 1.6rem;
    }
    .contact-main-title{
        padding-top:60px;
        width:60%;
    }
    .contact-img{
        width:80%;
    }
    .imge{
        height: 400px;
    }
}
@media only screen and (min-width: 992px) {
    .contact-card-title{
        font-size:30px;
    }
    .contact-card-text{
        padding:1rem 1.7rem;
    }
    .contact-main-title{
        padding-top:70px;
        width:55%;
    }
    .contact-img{
        width:75%;
    }
    .imge{
        height: 400px;
    }
}
@media only screen and (min-width: 1200px) {
    .contact-card-title{
        font-size:32px;
    }
    .contact-card-text{
        padding:1rem 1.8rem;
    }
    .contact-main-title{
        padding-top:70px;
        width:55%;
    }
    .contact-img{
        width:70%;
    }
    .imge{
        height: 400px;
    }
}
@media only screen and (min-width: 1300px) {
    .contact-card-title{
        font-size:34px;
    }
    .contact-card-text{
        padding:1rem 1.9rem;
    }
    .contact-main-title{
        padding-top:80px;
        width:50%;
    }
    .contact-img{
        width:70%;
    }
    .imge{
        height: 400px;
    }
}
@media only screen and (min-width: 1400px) {
    .contact-card-title{
        font-size:36px;
    }
    .contact-card-text{
        padding:1rem 2rem;
    }
    .contact-main-title{
        padding-top:80px;
        width:50%;
    }
    .contact-img{
        width:70%;
    }
    .imge{
        height: 400px;
    }
}
</style>
