<template>
<div><br />
  <div class="client-container">
    <br />
    <div class="center" style="text-align:center;">
      <h4 class="client-above-main-title">OUR HAPPY CLIENTS</h4>
      <h1 class="client-main-title medium-title">Some of Our Happy Clients.</h1>
      <p class="client-sub-title">We listen. We advise. We design. “Together”. Happy customers and ongoing relationships are what we strive for.</p><br />

      <VueSlickCarousel v-bind="settings">
        <div v-for="(client, i) in clients" :key="i">
          <div class="logo-container">
            <v-img :src="client.img" class="logo-img"/>
          </div>
        </div>
      </VueSlickCarousel><br />

    </div><br />
  </div><br />
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default{
  components: { VueSlickCarousel },
  data(){
    return{
      settings:{
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      },
      clients:[
        {
          img: require("@/assets/img/asanbazar.png"),
        },
        {
          img: require("@/assets/img/mobiles.png"),
        },
        {
          img: require("@/assets/img/luza.png"),
        },
        {
          img: require("@/assets/img/bhagawoti.png"),
        },
        {
          img: require("@/assets/img/packandpedal.png"),
        },
        {
          img: require("@/assets/img/client1.jpg"),
        },
        {
          img: require("@/assets/img/client2.jpg"),
        },
        {
          img: require("@/assets/img/client3.png"),
        },
        {
          img: require("@/assets/img/client4.png"),
        },
        {
          img: require("@/assets/img/client5.png"),
        },
        {
          img: require("@/assets/img/client6.png"),
        },
        {
          img: require("@/assets/img/client7.png"),
        },
        {
          img: require("@/assets/img/client8.png"),
        },
      ],
    }
  }
}
</script>

<style scoped>
.client-container{
  background:#F3F3F3;
}
.client-main-title{
  font-size:40px;
  color:#2D2C2C;
  margin-bottom:10px;
}
.client-above-main-title{
  color:#ABABAC;
  letter-spacing: 3px;
  margin:10px 0;
}
.client-sub-title{
  color:#ABABAC;
  margin:10px 0;
}
.logo-container{
  width:100%;
  height:300px;
  position: relative;
}
.logo-img{
  width:90%;
  margin:auto;
  max-height: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>


