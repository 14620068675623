<template>
<div id="contact">

    <v-parallax style="height:80vh;">

        <div :style="{ backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)),
        url(${groupimage})`}" class="background-img" :src="groupimage">

            <v-row align="center" justify="center" style="z-index:1000;color:white">
                <v-col cols="11" sm="10" md="8" lg="6" class="main-title">
                    <span style="color:#8FEF5D;">Hello!</span> Tell Us About Your Project. 
                </v-col>
            </v-row>

        </div>
    </v-parallax>
    
    <div class="contact-container container">

        <v-row class="contact-body-right rounded">
            <v-col cols="12" sm="5" class="contact-body-left rounded">
                <div class="contact-header">
                    <h2>Contact Us</h2>
                    <hr style="width:27%; text-align:left; margin-left:0; height:2px; background-color: white; border: none;"><br/>
                </div>
                <div class="list">
                    <ul style="list-style: none; color:white;" class="pl-0">
                        <li style="font-size:19px; margin-bottom:10px;">Address</li>
                        <li><a href="https://g.page/sts001?share" target="_blank" class="contact-link" style="text-decoration:none;">Kausaltar, Madhyapur Thimi-3, Bhaktapur.</a></li><br/>
                        <li style="font-size:19px; margin-bottom:10px;">Phone</li>
                        <li>Tel: <a href="tel:+977-1633004" class="contact-link" style="text-decoration:none;">+977-16633004</a></li>
                        <li>Mob: <a href="tel:+977-9849838989" class="contact-link" style="text-decoration:none;">+977-9849838989</a></li><br/>
                        <li style="font-size:19px; margin-bottom:10px;">Email</li>
                        <li><a href="mailto:info@stsolutions.com.np" class="contact-link" style="text-decoration:none;">info@stsolutions.com.np</a></li>
                    </ul>
                </div>
            </v-col>
            <v-col cols="12" sm="7" class="form-get-in-touch">
                <div class="form-header">
                    <h1 style="font-weight:500;">Get In Touch</h1>
                    <span>Please prefer to provide the valuable feedback</span><br/><br/>
                </div>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    label="Name"
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="Email address"
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="phone"
                    :rules="phoneRules"
                    label="Phone Number"
                    required
                    ></v-text-field>

                    <v-select
                    v-model="service"
                    :items="services"
                    item-text="title"
                    item-value="title"
                    label="Looking For"
                    :menu-props="{ offsetY: true }"
                    required
                    ></v-select>

                    <v-textarea
                    label="Message"
                    rows="3"
                    ></v-textarea>
                    
                    <v-btn
                    color="#77C74E"
                    dark
                    depressed
                    class="mr-4"
                    @click="reset"
                    >
                    Submit
                    </v-btn>
                </v-form>
            </v-col>

        </v-row>
    </div>
    
    <div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.3681736952976!2d85.36318631506137!3d27.67501348280537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1b77aba32ab5%3A0x76f67c54fb93aa55!2sSustainable%20Technological%20Solutions!5e0!3m2!1sen!2snp!4v1641193209428!5m2!1sen!2snp" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
</div>
</template>

<script>
import groupimage from "@/assets/img/contact.jpg"
import Data from "./../data"

export default {
    data() {
        return {
            groupimage,
            services: Data.it_solutions
        }
    }
}
</script>

<style scoped>
.background-img {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;

}
.main-title{
    font-weight: bold;
    text-align: center;
}

.contact-container {
    width: 100%;
    margin: 0 auto;
    padding-top: 0;
    min-height: 500px;
    padding-bottom: 80px;
    margin-top: -200px;
    position: relative;
}

.contact-header{
    margin-bottom: 5px;
}

.contact-header h2 {
    color: #ffffff;
    font-size:26px;
}

.contact-body-left {
    margin-left: -40px;
    background-color: #77C74E;
    height: 580px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.14);
    margin-top: 30px;
    color: #a8c6dc;
    padding: 40px;
}

.list .list-group .list-group-item {
    background-color: rgb(127, 209, 5);
    border: none;
    color: #ffffff;
    font-size: 1.2em;
}

.contact-body-right {
    width: 80%;
    margin: 0 auto !important;
    background-color: #ffffff;
    height: 640px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1), 0 3px 3px 0 rgba(0, 0, 0, 0.14);

}

.form-get-in-touch {
    padding: 50px;
}

.form-get-in-touch .form-header {
    color: #2D2C2C;
}

.form-get-in-touch .form-header span {
    color: #7D98AA;
    font-size: 1.2em;
}
.contact-link{
    color:#ffffff;
}
.contact-link:hover{
    color:#2D2C2C;
}

/* --------- Responsive ---------- */
@media only screen and (max-width: 400px) {
    .main-title{
        padding-top: 110px !important;
    }
    .contact-body-left {
        margin-left: 0;
        height: auto;
        margin-top: 0;
        padding: 20px;
    }
    .contact-body-right {
        height: auto;
        width:100%;
    }
    .contact-header{
        margin-bottom: 0;
    }
    .form-get-in-touch {
        padding: 30px;
    }
}
@media only screen and (min-width: 400px) {
    .main-title{
        padding-top: 120px !important;
    }
    .contact-body-left {
        margin-left: 0;
        height: auto;
        margin-top: 0;
        padding: 30px;
    }
    .contact-body-right {
        height: auto;
        width:95%;
    }
    .contact-header{
        margin-bottom: 0;
    }
    .form-get-in-touch {
        padding: 35px;
    }
}
@media only screen and (min-width: 600px) {
    .main-title{
        padding-top: 130px !important;
    }
    .contact-body-left {
        margin-left: -20px;
        height: 580px;
        margin-top: 30px;
        padding: 40px;
    }
    .contact-body-right {
        height: 640px;
        width:95%;
    }
    .contact-header{
        margin-bottom: 5px;
    }
    .form-get-in-touch {
        padding: 40px;
    }
}
@media only screen and (min-width: 768px) {
    .main-title{
        padding-top: 140px !important;
    }
    .contact-body-left {
        margin-left: -20px;
        height: 580px;
        margin-top: 30px;
        padding: 40px;
    }
    .contact-body-right {
        height: 640px;
        width:90%;
    }
    .contact-header{
        margin-bottom: 5px;
    }
    .form-get-in-touch {
        padding: 42px;
    }
}
@media only screen and (min-width: 992px) {
    .main-title{
        padding-top: 150px !important;
    }
    .contact-body-left {
        margin-left: -30px;
        height: 580px;
        margin-top: 30px;
        padding: 40px;
    }
    .contact-body-right {
        height: 640px;
        width:85%;
    }
    .contact-header{
        margin-bottom: 5px;
    }
    .form-get-in-touch {
        padding: 44px;
    }
}
@media only screen and (min-width: 1200px) {
    .main-title{
        padding-top: 160px !important;
    }
    .contact-body-left {
        margin-left: -30px;
        height: 580px;
        margin-top: 30px;
        padding: 40px;
    }
    .contact-body-right {
        height: 640px;
        width:85%;
    }
    .contact-header{
        margin-bottom: 5px;
    }
    .form-get-in-touch {
        padding: 46px;
    }
}
@media only screen and (min-width: 1300px) {
    .main-title{
        padding-top: 170px !important;
    }
    .contact-body-left {
        margin-left: -40px;
        height: 580px;
        margin-top: 30px;
        padding: 40px;
    }
    .contact-body-right {
        height: 640px;
        width:80%;
    }
    .contact-header{
        margin-bottom: 5px;
    }
    .form-get-in-touch {
        padding: 48px;
    }
}
@media only screen and (min-width: 1400px) {
    .main-title{
        padding-top: 180px !important;
    }
    .contact-body-left {
        margin-left: -40px;
        height: 580px;
        margin-top: 30px;
        padding: 40px;
    }
    .contact-body-right {
        height: 640px;
        width:80%;
    }
    .contact-header{
        margin-bottom: 5px;
    }
    .form-get-in-touch {
        padding: 50px;
    }
}

</style>
