<template>
    <div class="center">
        <div style="padding:20px 0;">
            <h4 class="approach-above-main-title">START WITH A DEMO</h4>
            <h1 class="approach-main-title medium-title"><span style="color:#47D14F;">Explore Various Projects</span> that We Have Completed.</h1>
        </div>

        <v-tabs
        v-model="tab"
        centered
        hide-slider
        >
            <v-tab
                v-for="(project,i) in project_types"
                :key="i"
                @click="changeContent(project)"
            >
                {{ project }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item 
                v-for="(project,i) in project_types"
                :key="i"
                transition="fade-transition"
            >
                <v-card flat>
                  <v-card-text>
                    <div v-if="project == 'All'">
                      <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3"  
                            v-for="(content,i) in contents"
                            :key="i"
                            style="padding:30px 25px 25px 25px;"
                        >
                          <a :href="content.link" target="_blank" style="text-decoration:none;">
                            <div class="flip-box">
                              <div class="flip-box-inner">
                                <div class="flip-box-front">
                                  <v-img :src="content.image" class="project-image"/>
                                </div>
                                
                                <div class="flip-box-back" v-if="content.type == 'Design Development'">
                                  <v-row style="margin:auto">
                                    <v-col cols="12" sm="6" v-for="image in content.images" :key="image">
                                      <v-img :src="image" style="width:100%; height:auto;"/>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div class="flip-box-back" v-else>
                                  <h2 style="color:#77C74E;">{{ content.name }}</h2>
                                  <p style="margin-top:10px">{{ content.description }}</p>
                                </div>
                              </div>
                            </div>
                            <div style="text-align:left;" class="project-name">{{ content.name }}</div>
                          </a>

                        </v-col> 
                      </v-row>

                      <v-pagination
                        v-if="totalItems>pageLimit"
                        v-model="page"
                        @input="changePage"
                        :length="Math.ceil(totalItems/pageLimit)"
                        color="#77C74E"
                        style="margin-top:20px;"
                      ></v-pagination><br/>

                    </div> 

                    <div v-else>
                      <v-row>
                        <v-col cols="12" sm="6" md="4" lg="3"  
                            v-for="(content,i) in sliced_projects"
                            :key="i"
                            style="padding:30px 25px 25px 25px;"
                        >
                          <div class="flip-box">
                            <div class="flip-box-inner">
                              <div class="flip-box-front">
                                <v-img :src="content.image" class="project-image"/>
                              </div>

                              <div class="flip-box-back" v-if="content.type == 'Design Development'">
                                  <v-row style="margin:auto">
                                    <v-col cols="12" sm="6" v-for="image in content.images" :key="image">
                                      <v-img :src="image" style="width:100%; height:auto;"/>
                                    </v-col>
                                  </v-row>
                                </div>
                                <div class="flip-box-back" v-else>
                                  <h2 style="color:#77C74E;">{{ content.name }}</h2>
                                  <p style="margin-top:10px">{{ content.description }}</p>
                                </div>
                            </div>
                          </div>
                          <div style="text-align:left;" class="project-name">{{ content.name }}</div>

                        </v-col> 
                      </v-row>

                      <v-pagination
                        v-if="totalItems2>pageLimit"
                        v-model="page2"
                        @input="changePage2"
                        :length="Math.ceil(totalItems2/pageLimit)"
                        color="#77C74E"
                        style="margin-top:20px;"
                      ></v-pagination><br/>

                    </div>
                      
                  </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <br/>

    </div> 
</template>

<script>
import Data from './../data'

export default {
  name:'ProjectsList',
  data(){
    return{
      page:1,
      contents: Data.projects,
      pageLimit: 8,
      totalItems: Data.projects.length,
      tab: null,
      project_types: [
        'All', 'Web Development', 'Android Development', 'Design Development'
      ],  
      page2:1,
      projects: [],
      sliced_projects: [],
      totalItems2: 0,
    }   
  },
  created() {
    this.contents = Data.projects.slice((this.page-1)* this.pageLimit, this.page* this.pageLimit)  
  },
  methods: {
    changeContent(project) {
      this.projects = Data.projects.filter(item => item.type === project)
      this.totalItems2 = this.projects.length
      this.sliced_projects = this.projects.slice((this.page2-1)* this.pageLimit, this.page2* this.pageLimit)
    },
    changePage(page){
      this.contents = Data.projects.slice((page-1)* this.pageLimit, page* this.pageLimit) 
    },
    changePage2(page2){
      this.sliced_projects = this.projects.slice((page2-1)* this.pageLimit, page2* this.pageLimit) 
    }
  }
}
</script>

<style scoped>
.approach-above-main-title{
  text-align:center;
  color:#ABABAC;
  letter-spacing: 3px;
  margin:10px 0;
}
.approach-main-title{
    text-align:center;
    font-weight: 600;
}
.project-image{
  height:310px;
  box-shadow: 1px 3px 10px 5px rgba(0, 0, 0, 0.1);
}
.project-name{
  font-size:18px;
  color: #000000;
  margin-top:15px;
}

.flip-box {
  background-color: transparent;
  height:310px;
  perspective: 1000px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  background-color: #bbb;
  color: black;
}

.flip-box-back {
  background-color: #54595F;
  color: white;
  transform: rotateY(180deg);
  padding:20px;
}
</style>