<template>
    <section id="hero">

        <v-carousel height="90vh" hide-delimiters cycle>

            <v-carousel-item eager v-for="(item,i) in items" :key="i">
                <div :style="{backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
                url(${item.src})`}" class="image" :src="item.src">

                    <v-row class="center" style="z-index:1000; color:white;">

                        <v-col cols="12" sm="9" md="8" lg="7" class="text-card">
                            <div>
                                <span class="child1 main-title">IT Services</span>
                                <span class="child2 main-title">To Develop Solutions Your Way!</span>
                            </div>
                            
                            <p class="is">
                                As an established technology-intensive company, we pride ourselves on providing a comprehensive suite of solutions consisting of infrastructure consultancy, on/off site services, custom software and web development, software and web application testing, and enterprise architecture consulting. 
                            </p>
                        </v-col>

                        <v-col cols="12" sm="3" md="4" lg="5" align="right">
                            <v-img class="ex experience-img" height="auto" src="@/assets/img/experience.png" />
                        </v-col>

                    </v-row>
                </div>
            </v-carousel-item>

        </v-carousel>

    </section>
</template>

<script>
export default {
    data() {
        return {
            absolute: true,
            overlay: false,
            screenHeight: window.screen.availHeight - (0.15 * window.screen.availHeight),
            items: [
                {
                    src: require('@/assets/img/back.jpg')
                },
                {
                    src: require('@/assets/img/back2.png')
                },
            ],

        }
    }
}
</script>

<style scoped>


.image {
    position: absolute;
    z-index: 0;
    width: 100%;
    height:100%;
    background-position: center;
    background-size: cover;

}

.font {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 37px;
}

.text-card{
    padding-top:200px;
}
.child1 {
    font-size: 50px;
    font-weight: bold;
    margin-right:10px;
}

.child2 {
    font-size: 50px;
    font-weight: normal;
    padding-top: 2px;
}
.is{
    font-size: 14px;
    font-weight: 300;
    color:white;
    margin-top:10px;
}
.ex {
    margin-top: 450px;

}

/* --------- Responsive ---------- */
@media only screen and (max-width: 400px) {
    .text-card{
        padding-top: 100px;
    }
}
@media only screen and (min-width: 400px) {
    .text-card{
        padding-top: 130px;
    }
}
@media only screen and (min-width: 600px) {
    .text-card{
        padding-top: 150px;
    }
}
@media only screen and (min-width: 768px) {
    .text-card{
        padding-top: 160px;
    }
}
@media only screen and (min-width: 992px) {
    .text-card{
        padding-top: 170px;
    }
}
@media only screen and (min-width: 1200px) {
    .text-card{
        padding-top: 180px;
    }
}
@media only screen and (min-width: 1300px) {
    .child1{
        font-size: 50px;
    }
    .child2{
        font-size: 50px;
    }
    .text-card{
        padding-top: 190px;
    }
}
@media only screen and (min-width: 1400px) {
    .child1{
        font-size: 50px;
    }
    .child2{
        font-size: 50px;
    }
    .text-card{
        padding-top: 200px;
    }
}


</style>
