<template>
<section id="about">

    <v-parallax style="background:#F3F3F3; height:72vh;">
        <div :style="{ backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
            url(${topimage})`}" class="imge" :src="topimage">

                <div class="text-div center" align="center" justify="center">
                    <div class="main-title">
                        {{ title }}
                    </div>
                    <p class="is" style="color:white">
                        {{ subtitle }}
                    </p>
                </div>

                <div class="center" align="right" justify="right">
                    <v-img class="experience-img" height="auto" src="@/assets/img/experience.png"/>
                </div>
            
        </div>
    </v-parallax>
</section>
</template>

<script>
export default {
    props: {
        title: {
            type: Object,
            required: true,
        },
        subtitle: {
            type: Object,
            required: true,
        },
        topimage: {
            type: Image,
            required: true,
        },
    },
}
</script>

<style scoped>
.imge {
    position: absolute;
    z-index: 0;
    width: 100%;
    height:100%;
    background-position: center;
    background-size: cover;

}
.text-div{
    padding-top: 200px;
    text-align: center;
    z-index:1000;
    color:white;
}
.main-title{
    color:#8FEF5D;
    font-weight: bold;
    margin-bottom:7px;
}
</style>
