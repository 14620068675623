<template>

    <v-app-bar class="main-nav" :color=" scrollPosition < 50 ? bgcolor :'#313131'" app :elevation="scrollPosition < 50 ? '0':'3' " dark elevate-on-scroll height="80">
        <router-link to="/" style="margin:10px 0;">
            <div class="logo-div">
                <v-img src="@/assets/img/log1.png" class="logo" />
            </div>  
        </router-link>

        <v-spacer></v-spacer>
        <v-list class="nav-bar" style="background:transparent;">
            <v-list-item v-for="(menu,index) in menus" :key="index" class="nav-bar-item" style="padding: 0 5px;">
                <v-menu offset-y v-if="menu.route==('/ourwork')" open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"      
                            v-on="on"
                            text
                            style="text-transform:capitalize; font-weight:700; letter-spacing:0;"
                        >
                            Our Works
                            <v-icon
                                dark
                            >
                                mdi-chevron-down
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list style="z-index:1000; background:#313131;">
                        <v-list-item
                        v-for="submenu in submenus"
                        :key="submenu.title"
                        link
                        :to="submenu.route"
                        >
                        <v-list-item-title style="color:#ffffff;">{{ submenu.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn color="#77C74E" style="text-transform:none; font-weight:700; letter-spacing:0;" v-else-if="menu.route==('/contactus')" :to="menu.route" >{{menu.title}}</v-btn>

                <v-btn v-else :to="menu.route" text style="text-transform:capitalize; font-weight:700; letter-spacing:0;">{{menu.title}}</v-btn>
                
            </v-list-item>

        </v-list>

        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="menu-btn"></v-app-bar-nav-icon>

        <v-navigation-drawer
            v-model="drawer"
            color="#313131"
            absolute
            right
            temporary
            app
        >
            <v-list
                nav
                dense
            >   <v-list-item v-for="(menu,index) in menus" :key="index" class="nav-bar-item" style="padding: 0 5px;">
                    <v-menu offset-y v-if="menu.route==('/ourwork')">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"      
                                v-on="on"
                                text
                                style="text-transform:capitalize; font-weight:700; letter-spacing:0;"
                            >
                                Our Works
                                <v-icon
                                    dark
                                >
                                    mdi-chevron-down
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list style="z-index:1000; background:#313131;">
                            <v-list-item
                            v-for="submenu in submenus"
                            :key="submenu.title"
                            link
                            :to="submenu.route"
                            >
                            <v-list-item-title style="color:#ffffff;">{{ submenu.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn color="#77C74E" style="text-transform:none; font-weight:700; letter-spacing:0;" v-else-if="menu.route==('/contactus')" :to="menu.route" >{{menu.title}}</v-btn>

                    <v-btn v-else :to="menu.route" text style="font-weight:700; letter-spacing:0;">{{menu.title}}</v-btn>
                    
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

    </v-app-bar>
</template>

<script>
export default {
    props: {
        bgcolor: {
            default:'transparent'
        },
    },
    data: function () {
        return {
            drawer:false,
            scrollPosition: 0,
            menus: [{
                    title: "IT Solutions",
                    route: "/itsolutions"
                },
                {
                    title: "News & Articles",
                    route: "/news"
                },
                {
                    title: "Our Work",
                    route: "/ourwork"
                },
                {
                    title: "About",
                    route: "/about"
                },
                {
                    title: "Careers",
                    route: "/careers"
                },
                {
                    title: "Contact Us",
                    route: "/contactus"
                }
            ],
            submenus: [
                {
                    title: "Products",
                    route: "/products"
                },
                {
                    title: "Projects",
                    route: "/projects"
                },
            ]
        };

    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        },
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    }
};
</script>

<style scoped>
.main-nav{
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 5%;
}
.logo-div {  
    width: 270px;
    height: auto;
}
.logo {  
    width: 100%;
    height: auto;
    object-fit: cover;
}

.transparentbg {
    background: transparent;
}
.nav-bar{
    display: flex;
}

/* --------- Responsive ---------- */
@media only screen and (max-width: 400px) {
    .nav-bar{
        display:none;
    }
    .menu-btn{
        display:block;
    }
    .main-nav{
        padding: 0 2%;
    }
    .logo-div {  
        width: 240px;
    }
}
@media only screen and (min-width: 400px) {
    .nav-bar{
        display:none;
    }
    .menu-btn{
        display:block;
    }
    .main-nav{
        padding: 0 2%;
    }
    .logo-div {  
        width: 240px;
    }
}
@media only screen and (min-width: 600px) {
    .nav-bar{
        display:none;
    }
    .menu-btn{
        display:block;
    }
    .main-nav{
        padding: 0 3%;
    }
    .logo-div {  
        width: 245px;
    }
}
@media only screen and (min-width: 768px) {
    .nav-bar{
        display:none;
    }
    .menu-btn{
        display:block;
    }
    .main-nav{
        padding: 0 3%;
    }
    .logo-div {  
        width: 250px;
    }
}
@media only screen and (min-width: 992px) {
    .nav-bar{
        display:flex;
    }
    .menu-btn{
        display:none;
    }
    .main-nav{
        padding: 0 4%;
    }
    .logo-div {  
        width: 255px;
    }
}
@media only screen and (min-width: 1200px) {
    .nav-bar{
        display:flex;
    }
    .menu-btn{
        display:none;
    }
    .main-nav{
        padding: 0 4%;
    }
    .logo-div {  
        width: 260px;
    }
}
@media only screen and (min-width: 1300px) {
    .nav-bar{
        display:flex;
    }
    .menu-btn{
        display:none;
    }
    .main-nav{
        padding: 0 5%;
    }
    .logo-div {  
        width: 265px;
    }
}
@media only screen and (min-width: 1400px) {
    .nav-bar{
        display:flex;
    }
    .menu-btn{
        display:none;
    }
    .main-nav{
        padding: 0 5%;
    }
    .logo-div {  
        width: 270px;
    }
}
</style>
