<template>
<section id="carrer">

    <v-parallax style="background:#F3F3F3; height:72vh;">
        <div :style="{ backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)),
            url(${groupimage})`}" class="imge" :src="groupimage">

            <div class="text-div center" align="center" justify="center">
                <div class="main-title">
                    IT Solutions
                </div>
                <p class="is" style="color:white">
                    We identify your business user base, determine your requirements and take a systematic approach to provide quality software solutions.
                </p>
                <v-btn color="#77C74E" to="/contactus" large dark style="text-transform:capitalize;">Contact Us Today</v-btn>
            </div>

            <div class="center" align="right" justify="right">
                <v-img class="experience-img" height="auto" src="@/assets/img/experience.png"/>
            </div>
            
        </div>
    </v-parallax>
</section>
</template>

<script>
import groupimage from "./../assets/img/it.jpeg"

export default {
    data() {
        return {
            groupimage,
        }
    },
    props: {
        career: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>
.imge {
    position: absolute;
    z-index: 0;
    width: 100%;
    height:100%;
    background-position: center;
    background-size: cover;

}
.text-div{
    padding-top: 200px;
    text-align: center;
    z-index:1000;
    color:white;
}
.main-title{
    color:#8FEF5D;
    font-weight: bold;
    margin-bottom:7px;
}
.green-label{
    background: #47D14F;
    padding:10px 20px;
    font-weight: 600;
    font-size:20px;
}

</style>
